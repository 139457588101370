import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"

import creation_sur_mesure_img from "../images/images/img_section/creation_sur_mesure.jpg"

import { Button, Modal } from "antd"

const Creation = () => {

//Hooks
  const [dataContact, setDataContact] = useState(false)

//Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
  <Layout>
    <SEO title="Création sur mesure" />

    <div className="slideshow">
      <div className="usp">
        <div  className="usp-txt-header">Création sur mesure</div>
      </div>
    </div>
    <div className="border-gold-bottom"></div>


    <section>
      <div className="savoir-faire-content">
        <img src={creation_sur_mesure_img} className="img"  alt='Bijoux, Création sur mesure'/>
        <div>
          <h3 className="h3-mobile" style={{textAlign: 'center'}}>Sur demande, nous réalisons le bijou qui vous ressemble.</h3>
          <p className="p-2 p-font mobile-display">Sur demande, nous réalisons le bijou qui vous ressemble.</p>
          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Création sur mesure') }>Nous contacter</Button></div>
        </div>
      </div>
    </section>

    <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
      visible={ showModalContact }
      onOk={ handleContactFormOk }
      onCancel={ handleContactFormCancel }
      okButtonProps={{ style: { display: 'none' } }}
    >

      <ContactForm page={dataContact} />

    </Modal>

  </Layout>
    </>
  )
}


export default Creation
